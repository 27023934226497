import { ActionTree, GetterTree, MutationTree } from "vuex";
import {
  KoalaLoginResponse,
  KoalaTokenResponse,
  KokattoTokenResponse,
  ProductCatalogTokenResponse,
} from "@/serviceClients/responses/KoalaLoginResponse";
import router from "@/router";
import { RefreshableRequestHelper } from "@/helpers/RefreshableRequestHelper";
import { KoalaAccountServiceClient } from "@/serviceClients/KoalaAccountServiceClient";
import { KokattoAuthServiceClient } from "@/serviceClients/KokattoAuthServiceClient";
import { KoalaPlusAccountActivityRequest } from "@/serviceClients/requests/KoalaPlusAccountActivityRequest";
import { KoalaPlusAccountActivityServiceClient } from "@/serviceClients/KoalaPlusAccountActivityServiceClient";

type State = {
  isLoggedIn: boolean;
  clientId: string;
  koalaToken: {
    accessToken: string;
    refreshToken: string;
  };
  productCatalogToken: {
    accessToken: string;
    accessTokenExptime: number;
    refreshToken: string;
    refreshTokenExptime: number;
  };
  kokattoToken: {
    token: string;
    tokenExpireAt: number;
    refreshToken: string;
    refreshTokenExpireAt: number;
    role: string;
    features: number;
    clientId: string;
    daysTillExpiration: string;
    featuresAccessName: string;
  };
  loginEmail: string;
  sharedLoginEmail: string;
  finishedTutorials: string[];
  subRole: string;
};

const state: State = {
  isLoggedIn: false,
  clientId: "",
  koalaToken: {
    accessToken: "",
    refreshToken: "",
  },
  productCatalogToken: {
    accessToken: "",
    accessTokenExptime: 0,
    refreshToken: "",
    refreshTokenExptime: 0,
  },
  kokattoToken: {
    token: "",
    tokenExpireAt: 0,
    refreshToken: "",
    refreshTokenExpireAt: 0,
    role: "",
    features: 0,
    clientId: "",
    daysTillExpiration: "",
    featuresAccessName: "",
  },
  loginEmail: "",
  sharedLoginEmail: "",
  finishedTutorials: [],
  subRole: "",
};

const getters: GetterTree<State, any> = {
  getIsLoggedIn: (state) => state.isLoggedIn,
  getKoalaTokenAccess: (state) => state.koalaToken.accessToken,
  getKoalaTokenRefresh: (state) => state.koalaToken.refreshToken,
  getProductCatalogTokenAccess: (state) =>
    state.productCatalogToken.accessToken,
  getProductCatalogTokenRefresh: (state) =>
    state.productCatalogToken.refreshToken,
  getKokattoTokenAccess: (state) => state.kokattoToken.token,
  getKokattoTokenRefresh: (state) => state.kokattoToken.refreshToken,
  getClientId: (state) => state.clientId,
  // getAccountRole: (state) => state.kokattoToken.role,
  getLoginEmail: (state) => state.loginEmail,
  getSharedLoginEmail: (state) => state.sharedLoginEmail,
  getFinishedCoachmark: (state) => state.finishedTutorials,
  getAccountEmailFromAccount: (state, getters) => getters.getAccountEmail,
  getAccountSubRole: (state) => state.subRole,
  getKokattoFeatureAccessName: (state) => state.kokattoToken.featuresAccessName,
};

const mutations: MutationTree<State> = {
  updateIsLoggedIn(state, isLoggedIn: boolean) {
    state.isLoggedIn = isLoggedIn ?? false;
  },
  updateClientId(state, clientId: string) {
    state.clientId = clientId;
  },
  updateKoalaToken(state, response: KoalaTokenResponse) {
    state.koalaToken = response;
  },
  updateProductCatalogToken(state, response: ProductCatalogTokenResponse) {
    state.productCatalogToken = response;
  },
  updateKokattoToken(state, response: KokattoTokenResponse) {
    state.kokattoToken = response;
  },
  updateLoginEmail(state, email: string) {
    state.loginEmail = email;
  },
  updateSharedLoginEmail(state, email: string) {
    state.sharedLoginEmail = email;
  },
  updateFinishedCoachmark: (state, listFinishedCoachmark: string[]) => {
    state.finishedTutorials = listFinishedCoachmark;
  },
  updateSubRole(state, subRole: string) {
    state.subRole = subRole;
  },
};

const actions: ActionTree<State, any> = {
  storeToken(context, koalaLoginResponse: KoalaLoginResponse) {
    const { koalaToken, productCatalogToken, kokattoToken } =
      koalaLoginResponse.data;
    context.commit("updateKoalaToken", koalaToken);
    context.commit("updateProductCatalogToken", productCatalogToken);
    context.commit("updateKokattoToken", kokattoToken);
    context.commit("updateIsLoggedIn", true);
  },
  storeClientId(context, koalaLoginResponse: KoalaLoginResponse) {
    const { clientId, role } = koalaLoginResponse.data;
    context.commit("updateClientId", clientId);
    context.commit("updateAccountRole", role);
  },
  // TODO: add send token to service worker
  storeTokenKokatto(context, koalaLoginResponse: KoalaLoginResponse) {
    const { kokattoToken } = koalaLoginResponse.data;
    context.commit("updateKokattoToken", kokattoToken);
  },
  storeTokenProductCatalog(context, koalaLoginResponse: KoalaLoginResponse) {
    const { productCatalogToken } = koalaLoginResponse.data;
    context.commit("updateProductCatalogToken", productCatalogToken);
  },
  storeTokenKoala(context, koalaLoginResponse: KoalaLoginResponse) {
    const { koalaToken } = koalaLoginResponse.data;
    context.commit("updateKoalaToken", koalaToken);
  },
  storeLoginEmail(context, email: string) {
    context.commit("updateLoginEmail", email);
    context.commit("updateSharedLoginEmail", email);
  },
  clearToken(context) {
    context.commit("updateKoalaToken", "");
    context.commit("updateKokattoToken", "");
    context.commit("updateProductCatalogToken", "");
    context.commit("updateIsLoggedIn", false);
    context.commit("updateLoginEmail", "");
    context.commit("updateSharedLoginEmail", "");
    context.commit("updateClientId", "");
  },
  setFinishedCoachmark(context, listFinishedCoachmark: string[]) {
    context.commit("updateFinishedCoachmark", listFinishedCoachmark);
  },
  clearStoreLogout({ dispatch, commit }) {
    dispatch("clearToken");
    dispatch("clearAccount");
    dispatch("clearConfiguration");
    dispatch("clearState");
    dispatch("clearMainOverlay");
    dispatch("clearAddress");
    dispatch("clearShowTemplateSubmiDisclaimer");
    dispatch("clearWSState");
    dispatch("clearDownloadedAttachments");
    dispatch("setAccountSubRole", "");
    dispatch("CustomerServicePlayMode/clearPlayModeData");
    commit("TicketChatListCustomHeader/resetStateToDefault");
    dispatch("setCsChatRole", "");
  },
  async logoutAccount({ dispatch }) {
    await dispatch("revokeAccountKokatto");
    await dispatch("revokeAccountActivity");
    dispatch("clearStoreLogout");
    router.push("/login");
  },
  async revokeAccountKokatto() {
    return RefreshableRequestHelper.requestKokatto<KoalaAccountServiceClient>(
      () => {
        const serviceClient = new KokattoAuthServiceClient();
        try {
          return serviceClient.updateStatusCSP();
        } catch (err) {
          console.log("Failed revoke account kokatto");
          return err as any;
        }
      }
    );
  },
  async revokeAccountActivity({ getters }) {
    const req: KoalaPlusAccountActivityRequest = {
      clientId: getters.getClientId,
      email: getters.getAccountEmailFromAccount,
    };
    return RefreshableRequestHelper.requestKoala<KoalaAccountServiceClient>(
      () => {
        // eslint-disable-next-line prefer-const
        let serviceClient = new KoalaPlusAccountActivityServiceClient();
        try {
          return serviceClient.deleteAccountActivity(req);
        } catch (err) {
          console.log("Failed revoke account activity");
          return err as any;
        }
      }
    );
  },
  setAccountSubRole(context, subRole: string) {
    context.commit("updateSubRole", subRole);
  },
};

const Auth = { state, getters, mutations, actions };

export default Auth;
